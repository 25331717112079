import React from "react"
import { CgCommunity } from "react-icons/cg"
import { BiKey } from "react-icons/bi"
import { Link } from "gatsby"

export default function CtaGetStarted() {
  return (
    <div className="relative pt-16 pb-32 overflow-hidden">
      <div className="relative">
        <h1 className="text-center text-black dark:text-gray-200 text-3xl font-extrabold pb-16">
          Get Started Today
        </h1>
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 bg-gray-100 dark:bg-gray-800 rounded-lg">
            <div className="mx-8">
              <div>
                <span className="h-12 w-12 rounded-lg flex items-center justify-center bg-red-300">
                  <CgCommunity
                    className="h-6 w-6 text-black"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-2xl tracking-tight text-gray-900 dark:text-gray-200">
                  Community Edition
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Download the Community Edition and get immediate access to
                  antMan.
                </p>
                <div className="mt-6">
                  <Link
                    to="/products/antmanedgelinux/download"
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-black bg-red-300 hover:bg-red-400"
                  >
                    Download Now
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2 md:pt-24 bg-gray-100 dark:bg-gray-800 rounded-lg md:py-8">
            <div className="mx-8">
              <div>
                <span className="h-12 w-12 rounded-lg flex items-center justify-center bg-red-300">
                  <BiKey className="h-6 w-6 text-black" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-2xl tracking-tight text-gray-900 dark:text-gray-200">
                  License & Features
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Need more capabilities? See all plans and pricing for business
                  license & features.
                </p>
                <div className="mt-6">
                  <Link
                    to="/pricing"
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-black bg-red-300 hover:bg-red-400"
                  >
                    View Pricing
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
