import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import HeroImgR from "../../components/Products/Hero/HeroImgR"
import CenteredTextBloc from "../../components/Blocs/CenteredTextBloc"
import LadderImgR from "../../components/Blocs/LadderImgR"
import LadderImgL from "../../components/Blocs/LadderImgL"
import { Link as LinkS } from "react-scroll"
import LogoCloud from "../../components/Products/LogoCloud"
import Features from "../../components/Features"
import { AiOutlinePlayCircle } from "react-icons/ai"
import CtaGetStarted from "../../components/Products/CtaGetStarted"
import RecentFC from "../../components/RecentFC"

const AntManEdgeLinux = () => (
  <>
    <Layout>
      <Seo title="AntManEdgeLinux" />
      <HeroImgR
        image="https://antsle.com/wp-content/uploads/2020/05/antMan-on-Screen-watch-now.png"
        alt="AntManEdgeLinux"
      >
        <h1 className="text-transparent bg-clip-text bg-gradient-to-r from-green-400 via-yellow-400 to-red-500 dark:from-green-200 dark:via-yellow-200 dark:to-red-400">
          antMan
        </h1>
        <p className="text-gray-800 dark:text-gray-100">
          Your Server(s) + antMan = Your Private Cloud
        </p>
      </HeroImgR>
      <CenteredTextBloc>
        <h1 className="text-black dark:text-gray-200">
          A Cloud that's 100% Yours
        </h1>
        <p className="text-gray-900 dark:text-gray-400">
          Manage your own VPSs in a private, on-prem cloud{" "}
        </p>
      </CenteredTextBloc>
      <LadderImgR
        image="https://antsle.com/wp-content/uploads/2020/05/antman-dashboard-on-laptop-small.png"
        alt="Manage your own VPS"
      >
        <p className="text-gray-900 dark:text-gray-200">
          Deploy antlets (your virtual private servers) in a world class
          management console right from your browser. We've got everything you
          need for your on-prem, private cloud — from backups to load balancing.{" "}
        </p>
        <p className="text-gray-900 dark:text-gray-200">
          antMan runs on edgeLinux, Antsle's purpose built OS. You can read more
          about it{" "}
          <LinkS
            className="text-pink-600 cursor-pointer"
            to="scroll"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={1000}
          >
            here.
          </LinkS>
        </p>
      </LadderImgR>
      <LogoCloud />
      <Features />
      <LadderImgL
        image="https://antsle.com/wp-content/uploads/2020/05/Backups-Highlight-4.png"
        alt="Smart Backups"
      >
        <h1 className="text-gray-900 dark:text-gray-200">Smart Backups</h1>
        <p className="text-gray-900 dark:text-gray-200">
          Be ready for any disaster or network outage by enabling backups of
          your antlets. Heal antlets to any other Antsle server with a click.
          Flexible backends include self-managed storage or the Antsle Cloud.
        </p>
        <a href="https://youtu.be/65TCFU1RKGM">
          <button
            type="button"
            className="inline-flex items-center mt-8 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          >
            <AiOutlinePlayCircle
              className="-ml-0.5 mr-2 h-4 w-4"
              aria-hidden="true"
            />{" "}
            Smart Backups in Action
          </button>
        </a>
      </LadderImgL>
      <LadderImgR
        image="https://antsle.com/wp-content/uploads/2020/05/Antsle-Clustering-Load-Balancing-1.png"
        alt="Clustering"
      >
        <h1 className="text-gray-900 dark:text-gray-200">Clustering</h1>
        <p className="text-gray-900 dark:text-gray-200">
          Manage multiple nodes (physical servers) in antMan and set up load
          balancing rules to distribute traffic between your antlets. Easily
          scale out your storage, memory, and compute by adding new nodes.
        </p>
        <a href="https://youtu.be/NMrFgaDV4r8">
          <button
            type="button"
            className="inline-flex items-center mt-8 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          >
            <AiOutlinePlayCircle
              className="-ml-0.5 mr-2 h-4 w-4"
              aria-hidden="true"
            />{" "}
            Clustering in Action
          </button>
        </a>
      </LadderImgR>
      <LadderImgL
        image="https://antsle.com/wp-content/uploads/2020/05/Antsle-Protector-Mode-1-1.png"
        alt="Protector Mode"
      >
        <h1 className="text-gray-900 dark:text-gray-200">Protector Mode</h1>
        <p className="text-gray-900 dark:text-gray-200">
          Flip the switch and transform your antMan. Create, Deploy, and Destroy
          Groups of antlets in a click. Purpose built to reduce the time you
          spend configuring your infrastructure from hours to seconds.
        </p>
      </LadderImgL>
      <LadderImgR
        image="https://antsle.com/wp-content/uploads/2020/05/DFS-Highlight-2.png"
        alt="Distributed File System"
      >
        <h1 className="text-gray-900 dark:text-gray-200">
          Distributed File System
        </h1>
        <p className="text-gray-900 dark:text-gray-200">
          Create a shared storage volume that spreads across all your nodes
          (physical servers). You can unplug a node and throw it out the window
          — everything will keep humming along. This makes your infrastructure
          highly available and easy to scale.
        </p>
      </LadderImgR>
      <CtaGetStarted />
      <LadderImgR
        image="https://antsle.com/wp-content/uploads/2020/05/edgeLinux-Overview-2.png"
        alt="Distributed File System"
      >
        <h1 className="text-gray-900 dark:text-gray-200" id="scroll">
          Architecture Overview
        </h1>
        <p className="text-gray-900 dark:text-gray-200">
          edgeLinux is our purpose-built operating system. It works behind the
          scenes to give antMan its power ⚡.
        </p>
        <p className="text-gray-900 dark:text-gray-200">
          All your servers with edgeLinux can be linked together and controlled
          by antMan or via the REST API.
        </p>
      </LadderImgR>
      <RecentFC />
    </Layout>
  </>
)

export default AntManEdgeLinux
