import React from 'react'
import PropTypes from 'prop-types'
import './blocs.scss'

export default function FeatureImgL ({ image, alt, children}) {
    return (
        <div className="imgl">
            <div className="imgl__container">
                <div className="imgl__container--left">
                    <img 
                        className="imgl__container--img"
                        src={image}
                        alt={alt} />
                </div>
                <div className="imgl__container--right">
                    {children}
                </div>
            </div>
        </div>
    )
}

FeatureImgL.propTypes = {
    image: PropTypes.any.isRequired,
    alt: PropTypes.string,
    children: PropTypes.node.isRequired,
}