import { Link } from 'gatsby'
import React from 'react'

const posts = [
    {
      title: 'Software Defined Networking',
      href: '/freedomcasts/episode-25-software-defined-networking',
      description:
        'Create virtual LANs, mirror traffic, created a bridged NIC for your antlet and more.',
    },
    {
      title: 'Using Antsle for Factory Systems Automation',
      href: '/freedomcasts/episode-22',
      description:
        'See how plants from GE to Dumond Farms use Antsle to produce smarter and faster.',
    },
    {
      title: 'Multi Node Management and Load Balancing',
      href: '/freedomcasts/episode-23-load-balancing-and-multinode',
      description:
        'Learn how manage multiple nodes and set up load balancing with Antsle.',
    },
  ]
  
  export default function RecentFC() {
    return (
      <div className="pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
          <div>
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 dark:text-gray-200 sm:text-4xl">See the FreedomCasts</h2>
            <p className="mt-3 text-xl text-gray-500 sm:mt-4">
            antMan explained in screencasts.{" "}<Link to="/freedomcasts" className="text-pink-600">View All Episodes{" >"}</Link>
            </p>
          </div>
          <div className="mt-12 grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
            {posts.map((post) => (
              <div key={post.title}>
                <div>
                  <div className="inline-block">
                    <span
                      className='bg-indigo-100 text-indigo-800 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium'
                    >
                      New
                    </span>
                  </div>
                </div>
                <a href={post.href} className="block mt-4">
                  <p className="text-xl font-semibold text-gray-900 dark:text-gray-200">{post.title}</p>
                  <p className="mt-3 text-base text-gray-500">{post.description}</p>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  