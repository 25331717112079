import React from "react"

export default function LogoCloud() {
  const logos = [
    {
      image: "https://cdn.worldvectorlogo.com/logos/docker-1.svg",
      alt: "Docker",
    },
    {
      image: "https://cdn.worldvectorlogo.com/logos/windows-server.svg",
      alt: "Windows Server",
    },
    {
      image: "https://cdn.worldvectorlogo.com/logos/ansible.svg",
      alt: "Ansible",
    },
    {
      image: "https://cdn.worldvectorlogo.com/logos/freebsd-1.svg",
      alt: "FreeBSD",
    },
    {
      image: "https://cdn.worldvectorlogo.com/logos/kubernets.svg",
      alt: "Kubernetes",
    },
    {
      image: "https://cdn.worldvectorlogo.com/logos/mysql-6.svg",
      alt: "MySQL",
    },
    {
      image: "https://i2.wp.com/softwareengineeringdaily.com/wp-content/uploads/2016/10/PostgreSQL.png",
      alt: "PostgreSLQ",
    },
    {
      image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/MongoDB_Logo.svg/2560px-MongoDB_Logo.svg.png",
      alt: "MongoDB",
    },
    {
      image: "https://cdn.worldvectorlogo.com/logos/nginx.svg",
      alt: "Nginx",
    },
    {
      image: "https://cdn.worldvectorlogo.com/logos/apache-13.svg",
      alt: "Apache",
    },
    {
      image: "https://cdn.worldvectorlogo.com/logos/wordpress-blue.svg",
      alt: "Wordpress",
    },
    {
      image: "https://cdn.worldvectorlogo.com/logos/gitlab-1.svg",
      alt: "GitLab",
    },
    {
      image: "https://www.saotn.org/wp-content/uploads/2014/07/IIS_logo.png",
      alt: "Microsoft IIS",
    },
  ]

  return (
    <div className="bg-gray-200 dark:bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-black dark:text-gray-900">
        Deploy your Favorite Apps
        </h2>
        <div className="flow-root mt-8 lg:mt-10">
          <div className="flex flex-wrap justify-between lg:-ml-4">
            {logos.map((logo) => (
              <div className="m-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
                <img className="h-12" src={logo.image} alt={logo.alt} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
