import React from 'react'
import PropTypes from 'prop-types'
import './blocs.scss'


export default function FeatureImgR ({ image, alt, children }) {
    return (
        <div className="imgr">
            <div className="imgr__container">
                <div className="imgr__container--left">
                    {children}
                </div>
                <div className="imgr__container--right">
                    <img 
                        className="imgr__container--img"
                        src={image}
                        placeholder="blurred"
                        alt={alt} />
                </div>
            </div>
        </div>
    )
}

FeatureImgR.propTypes = {
    image: PropTypes.any.isRequired,
    alt: PropTypes.string,
    children: PropTypes.node.isRequired,
}