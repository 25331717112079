import React from 'react'
import './blocs.scss'

export default function CenteredTextBloc ({ children }) {
    return (
        <div className="two">
            <div className="two__container">
                    {children}
            </div>
        </div>
    )
}
