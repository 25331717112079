import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { RiDownloadCloud2Fill } from "react-icons/ri"
import { BiCoinStack } from "react-icons/bi"
import "./hero.scss"

export default function HeroImgR({ image, alt, children }) {
  return (
    <div className="hero">
      <div className="hero__container">
        <div className="hero__container--left">
          {children}
          <div id="btn-container">
            <Link to="/products/antmanedgelinux/download">
              <button
                type="button"
                className="inline-flex items-center m-4 px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-black bg-green-400 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                <RiDownloadCloud2Fill
                  className="-ml-1 mr-3 h-5 w-5"
                  aria-hidden="true"
                />
                Download Now
              </button>
            </Link>
            <Link to="/pricing">
              <button
                type="button"
                className="inline-flex items-center m-4 px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-black bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
              >
                <BiCoinStack
                  className="-ml-1 mr-3 h-5 w-5"
                  aria-hidden="true"
                />
                See Pricing
              </button>
            </Link>
          </div>
        </div>
        <div className="hero__container--right">
          <img
            className="hero__container--img"
            src={image}
            placeholder="blurred"
            alt={alt}
          />
        </div>
      </div>
    </div>
  )
}

HeroImgR.propsTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.any.isRequired,
  alt: PropTypes.string,
}
