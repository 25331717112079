import React from "react"
import {
  AiFillRocket,
  AiOutlineBranches,
  AiOutlineAppstoreAdd,
  AiOutlineBuild,
  AiOutlineSafety,
  AiOutlineThunderbolt,
} from "react-icons/ai"

const features = [
  {
    name: "Instant Templates",
    description:
      "OS templates, instant snapshots, rollbacks. Create your own custom templates in a breeze.",
    icon: AiFillRocket,
  },
  {
    name: "Easy Networking",
    description:
      "Set up your virtual NICs, VLANs, and port forwarding rules. Super simple, point and click.",
    icon: AiOutlineBranches,
  },
  {
    name: "Full and Light Virtualization",
    description:
      "Get more out of your hardware. Run bare-metal containers side-by-side with VMs in a unified toolset.",
    icon: AiOutlineAppstoreAdd,
  },
  {
    name: "Agile Infrastructure",
    description:
      "Experience the same simplicity as you expand your compute and storage across multiple nodes.",
    icon: AiOutlineBuild,
  },
  {
    name: "World Class Support",
    description:
      'They`re the ones whose parents told them "no internet" at 13 and proceeded to hack the router.',
    icon: AiOutlineSafety,
  },
  {
    name: "Powerful and Flexible API",
    description:
      "Automate and manage your antlet army. Our OpenAPI compliant REST API integrates with Terraform.",
    icon: AiOutlineThunderbolt,
  },
]

export default function WhyLoveantMan() {
  return (
    <div className="relative bg-white dark:bg-black py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-base font-semibold tracking-wider text-red-800 dark:text-pink-500 uppercase">
          small heading here
        </h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900 dark:text-gray-200 tracking-tight sm:text-4xl">
          Why Love antMan?
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
          Some description about feautes here
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root bg-gray-50 dark:bg-gray-900 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-pink-800 rounded-md shadow-lg">
                        <feature.icon
                          className="h-6 w-6 text-white dark:text-gray-200"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 dark:text-gray-200 tracking-tight">
                      {feature.name}
                    </h3>
                    <p className="mt-5 text-base text-gray-600 dark:text-gray-400">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
